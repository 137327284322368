.m-footer {
	background-color: $color-gray;
	color: $color-white;
	padding-bottom: 1rem;

	&__logo {
		margin: 2rem 0;
		max-width: 18rem;
		width: auto;
		transform: translateY(15%);
	}

	&__link {
		color: $color-white;
		text-align: center;
		width: 20%;
		text-transform: uppercase;
		font-weight: 700;
		margin: .75rem .75rem;
		font-size: 1.2rem;
		display: inline-block;
	}

	.m-form {
		height: 100%;
		width: 100%;
		max-width: 100%;
		margin: 2rem 0;

		label {
			color: $color-white;
			font-size: $font-size-small;
			margin-bottom: 1rem;
			text-align: center;
		}

		input[type="text"], input[type="email"], input[type="number"] {
			padding: 1rem;
			display: inline-block;
			border: none;
			border-radius: 4px;
			max-width: 24rem;
			margin-bottom: 1rem;
		}
	}
}

body .gform_wrapper form .gform_body ul,
body .gform_wrapper form .gform_body ul li {
	list-style-type: none !important;
	list-style-image: none !important;
	list-style: none !important;
	background-image: none !important;
	background: none !important;
	padding: 0 !important;
	margin: 0 !important;
	border: none !important;


}
.gform_footer {
	list-style-type: none !important;
	list-style-image: none !important;
	list-style: none !important;
	background-image: none !important;
	background: none !important;
	padding: 0 !important;
	margin: 0 !important;
	border: none !important;
}
#gform_wrapper_3{
	margin-top: -5rem;
}
#gform_submit_button_3{
	width: auto;
}

#input_3_1{
	width: 120%;
	background-color: #ccc !important;
}

@media (max-width: 640px) {
	.subscribe{
		margin-bottom: -2rem;
	}
	#input_3_1{
		width:100% !important;
		background-color: #ccc !important;
	}
}

@media (min-width: 320px) and (max-width: 449px) {
	#input_3_1{
		width:140% !important;
		background-color: #ccc !important;
	}
}

body .gform_wrapper form .gform_body ul > li:before {
	content: "";
}
body #gform_wrapper_1 .gform_footer input[type=submit] {
	font-size: 1.5rem;
	padding: 1rem 1.5rem;
	background-color: #323232;
	color: #FFF !important;
	font-weight: 700;
	border-radius: 3px;
	text-transform: uppercase;
}

body #gform_wrapper_1 .gform_body .gform_fields .gfield .gfield_label {
	display: block;
	font-size: 1.8rem;
	margin-bottom: 1rem;
	font-weight:500;
}

#gform_submit_button_3{
	display: block;
	font-size: 1.5rem;
	padding: 1rem 1.5rem;
	text-transform: uppercase;
	border-radius: 4px;
	font-weight:700;
	color: black;
}
#gform_fields_3{
	list-style-type: none !important;
	list-style-image: none !important;
	list-style: none !important;
	background-image: none !important;
	background: none !important;
	padding: 0 !important;
	margin: 0 !important;
	border: none !important;
}
#menu-footer_menu li {
	color: #FFF;
	text-align: center;
	width: 20%;
	text-transform: uppercase;
	font-weight: 700;
	margin: .75rem .75rem;
	font-size: 1.2rem;
	display: flex;
	flex-wrap: wrap;
}
@media (max-width: 411px) {
	#menu-footer_menu li {
		margin-right: 0.5rem !important;
	}
	#menu-footer_menu li:nth-child(1) {
		margin-left: -2rem;
		margin-right: -1.2rem !important;
	}
	#menu-footer_menu li:last-child {
		margin-left: 5rem !important;
	}
}
//@media (min-width: 412px)  {
	//#menu-footer_menu li:last-child {
	//	margin-left: 3rem;
	//}
//}

#menu-footer_menu {
	display: contents;
}
#menu-footer_menu li a {
	color: #FFF;
	text-align: center;
	width: 100%;
	text-transform: uppercase;
	font-weight: 700;
	margin: .75rem .75rem;
	font-size: 1.2rem;
	display: inline-block;
}

.steun-blok{
	margin-top: 5rem;
	text-align: center;
}

.steun{
	padding: 1.2rem 4rem;
	text-decoration: none;
}

.footer-top{
	background-color: #fff;
}

@media (min-width: 450px) and (max-width: 540px) {
	#menu-footer_menu li:last-child {
		margin-left: 5rem;
	}
}
@media (max-width: 450px) {
	.footer-menu{
		display: block;
	}
	#menu-footer_menu li:last-child {
		margin-left: 0;
	}
}
@media (min-width: 768px) {
	.footer-menu{
		position:relative;
		left: 18%;
	}
}
